import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initializeFromItem } from '~/store/listFlow/initializationActions';
import { RootState } from '~/store/rootReducer';
import {
  RailsCategory,
  RailsDetail,
  RailsItem,
  RailsItemModel,
  RailsVariationDetail,
} from '~/typings/services/rails/item';

export interface ListFlowVariation {
  // IDs only exist on variations we want to edit, not create
  id?: number;
  name: string;
  quantity: number;
  details?: RailsVariationDetail[];
}

export interface ListFlowInfoState {
  state?: RailsItem['state'];
  // The main selected category.
  category?: RailsCategory;

  // All selected details
  // Keep track of the entire object, but only deal with IDs in the component.
  details: {
    [detailSlug: string]: {
      detail: RailsDetail;
      selected:
        | 'variations'
        | (RailsDetail & { imageRecognitionConfidence?: number })
        | null;
    };
  };

  model?: RailsItemModel | null;

  // Item name
  title?: string;
  generatedTitle?: string;
  usingGeneratedTitle?: boolean;
  description?: string;

  // Can be null/undefined to support variations, which have their own quantity
  quantity?: number | null;

  variations?: ListFlowVariation[];

  ebay: {
    id?: string;
  };

  privateDetails: {
    myCost?: number;
    inventoryId?: string;
    gtin?: string;
    mpn?: string;
  };
}

export const listFlowInfoInitialState: ListFlowInfoState = {
  state: 'draft',
  details: {},
  quantity: 1,
  privateDetails: {},
  ebay: {},
};

const listFlowInfoSlice = createSlice({
  initialState: listFlowInfoInitialState,
  name: 'listFlow/info',
  reducers: {
    initializeCategory(state, action: PayloadAction<RailsCategory>) {
      // Overwrite what's represented in state
      state.category = action.payload;
    },
    initializeDetails(state, action: PayloadAction<RailsDetail[]>) {
      for (const detail of action.payload) {
        // Overwrite master detail objects, keep selections
        const selected = state.details[detail.slug]?.selected || null;
        state.details[detail.slug] = {
          detail,
          selected,
        };
      }
    },
    changeCategory(state, action: PayloadAction<RailsCategory | null>) {
      const category = action.payload;
      state.details = {};
      state.model = null;

      if (category) {
        state.category = category;
      } else {
        state.category = undefined;
      }
    },
    changeDetail(
      state,
      action: PayloadAction<{
        detail: RailsDetail;
        selected: ListFlowInfoState['details'][string]['selected'] | null;
      }>,
    ) {
      const { detail, selected } = action.payload;

      // We need to remove the variation details
      if (
        state.variations &&
        state.details[detail.slug]?.selected === 'variations'
      ) {
        for (let i = 0; i < state.variations.length; i++) {
          const variation = state.variations[i];

          if (!variation.details) {
            continue;
          }

          const variationDetailIndex = variation.details.findIndex(
            d => d.type === detail.slug,
          );
          if (variationDetailIndex >= 0) {
            state.variations[i].details!.splice(variationDetailIndex, 1);
          }
        }
      }

      if (selected) {
        state.details[detail.slug] = { detail, selected };
      } else {
        // Remove from selection
        delete state.details[detail.slug];
      }

      if (detail.slug === 'brand') {
        state.model = undefined;
      }
    },
    changeModel(state, action: PayloadAction<RailsItemModel | null>) {
      state.model = action.payload;
    },
    changeQuantity(state, action: PayloadAction<number>) {
      state.quantity = action.payload;
    },
    changeTitle(state, action: PayloadAction<string>) {
      state.title = action.payload;
      state.usingGeneratedTitle = false;
    },
    changeDescription(state, action: PayloadAction<string>) {
      state.description = action.payload;
    },
    changedPrivateDetail<T extends keyof ListFlowInfoState['privateDetails']>(
      state,
      action: PayloadAction<{
        detail: T;
        value: ListFlowInfoState['privateDetails'][T];
      }>,
    ) {
      state.privateDetails[action.payload.detail] = action.payload.value;
    },
    createVariation(
      state,
      action: PayloadAction<{ newVariation: ListFlowVariation }>,
    ) {
      const { newVariation } = action.payload;
      if (state.variations) {
        state.variations.push(newVariation);
      } else {
        state.variations = [newVariation];
      }
    },
    changedEbayId(state, action: PayloadAction<{ id: string }>) {
      state.ebay.id = action.payload.id;
    },
    changeVariation(
      state,
      action: PayloadAction<{ index: number; newVariation: ListFlowVariation }>,
    ) {
      const { index, newVariation } = action.payload;
      if (state.variations?.[index]) {
        state.variations[index] = newVariation;
      }
    },
    deleteVariation(state, action: PayloadAction<{ index: number }>) {
      const { index } = action.payload;
      if (state.variations?.[index]) {
        state.variations.splice(index, 1);
      }
    },
  },
  extraReducers: builder => {
    //! HEY, if you're editing this reducer, check if you need to edit `getListFlowSubmittableItem`
    builder.addCase(initializeFromItem, (state, action): ListFlowInfoState => {
      const item = action.payload;

      // Copy existing detail information. We need to preserve details that
      // aren't selected but _could_ be.
      const details: ListFlowInfoState['details'] = {
        ...state.details,
      };

      const variations =
        item.variations && item.variations.length > 0
          ? item.variations.map(variation => ({
              id: variation.id,
              name: variation.name,
              quantity: variation.quantity,
              details: variation.details,
            }))
          : undefined;

      for (const detail of item.details) {
        const selectedChild = detail.values?.[0] as RailsDetail | undefined;
        if (selectedChild) {
          details[detail.slug] = {
            detail:
              state.details[detail.slug]?.detail || (detail as RailsDetail),
            selected: selectedChild || null,
          };
        }
      }

      // Go through variations and look for each one with a "type".
      // Set that type to "variations"
      if (variations) {
        for (const variation of variations) {
          if (Array.isArray(variation.details)) {
            for (const variationDetail of variation.details) {
              // Find this detail in state, we need to assign it as a "variation"
              // detail.

              for (const detailKey in details) {
                const detail = details[detailKey]?.detail;
                if (detailKey === variationDetail.type) {
                  details[variationDetail.type] = {
                    detail: detail,
                    selected: 'variations',
                  };
                }
              }
            }
          }
        }
      }

      return {
        details,
        variations,
        state: item.state,
        category: item.categories?.[0],
        model: item.model,
        quantity: item.quantity,
        title: item.name,
        description: item.description,
        privateDetails: {
          inventoryId: item.inventory_id,
          mpn: item.mpn,
          gtin: item.gtin,
          myCost: Number(item.cost) || undefined,
        },
        ebay: {
          id: item.ebay?.id,
        },
      };
    });
  },
});

export const {
  initializeCategory,
  initializeDetails,
  changeCategory,
  changeDetail,
  changeTitle,
  changeModel,
  changeVariation,
  createVariation,
  changedEbayId,
  changeQuantity,
  deleteVariation,
  changeDescription,
  changedPrivateDetail,
} = listFlowInfoSlice.actions;

const listFlowInfoReducer = listFlowInfoSlice.reducer;
export default listFlowInfoReducer;

export const getListFlowInfoState = (state: RootState) => state.listFlow.info;

export const getListFlowCategory = (state: RootState) =>
  state.listFlow.info.category;

export const getListFlowDetails = (state: RootState) =>
  state.listFlow.info.details;

export const getListFlowModel = (state: RootState) => state.listFlow.info.model;

export const getListFlowQuantity = (state: RootState) =>
  state.listFlow.info.quantity;

export const getListFlowTitle = (state: RootState) => state.listFlow.info.title;

export const getListFlowDescription = (state: RootState) =>
  state.listFlow.info.description;

export const getListFlowGeneratedTitle = (state: RootState) =>
  state.listFlow.info.generatedTitle;

export const isListFlowUsingGeneratedTitle = (state: RootState) =>
  state.listFlow.info.usingGeneratedTitle;

export const getListFlowVariations = (state: RootState) =>
  state.listFlow.info.variations;

export const getListFlowPrivateDetails = (state: RootState) =>
  state.listFlow.info.privateDetails;

export const getListFlowEbayId = (state: RootState) =>
  state.listFlow.info.ebay.id;

export const getListFlowVariationDetails = (state: RootState) => {
  const details: RailsDetail[] = [];

  for (const detailKey in state.listFlow.info.details) {
    const selection = state.listFlow.info.details[detailKey];
    if (selection?.selected === 'variations') {
      details.push(selection.detail);
    }
  }

  return details;
};

export const isListFlowShowingVariations = (state: RootState) =>
  state.listFlow.info.variations?.length! > 0;

export const getListFlowValueGuideData = (state: RootState) => {
  const info = state.listFlow.info;
  const query: Record<string, any> = {};

  if (info.category) {
    query.category = [info.category.id];
  }

  if (info.model) {
    query.model = [info.model.id];
  }

  if (info.details) {
    const entries = Object.entries(info.details);
    for (let i = 0; i < entries.length; i++) {
      const [detailSlug, value] = entries[i];
      if (
        value.detail.value_guides_required &&
        value.selected &&
        value.selected !== 'variations'
      ) {
        query[detailSlug] = [value.selected.id];
      }
    }
  }

  return query;
};

export const getListFlowItemDetailIds = (state: RootState) => {
  const details = state.listFlow.info.details;
  const ids: number[] = [];

  for (const detailKey in details) {
    const detail = details[detailKey];
    if (detail.selected && detail.selected !== 'variations') {
      ids.push(detail.selected.id);
    }
  }

  return ids;
};
