import {
  createReducer,
  isAnyOf,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import { FieldError, getErrorMessage } from 'fetcher-session';
import { current } from 'immer';
import {
  listFlowPublish,
  listFlowSaveDraft,
  listFlowValidate,
} from '~/store/listFlow/flow';
import {
  finishInitializingItem,
  initializeFromItem,
  startInitializationPhase,
} from '~/store/listFlow/initializationActions';
import listFlowInfoReducer, {
  ListFlowInfoState,
  changeCategory,
  changeDetail,
  changeModel,
  initializeCategory,
  initializeDetails,
  listFlowInfoInitialState,
} from '~/store/listFlow/slices/info';
import listFlowPhotoReducer, {
  ListFlowPhotoState,
} from '~/store/listFlow/slices/photos';
import listFlowPricingReducer, {
  ListFlowPricingState,
  listFlowPricingInitialState,
} from '~/store/listFlow/slices/pricing';
import listFlowShippingReducer, {
  ListFlowShippingState,
  listFlowShippingInitialState,
} from '~/store/listFlow/slices/shipping';
import { RootState } from '~/store/rootReducer';
import { PublishRailsItem, RailsItem } from '~/typings/services/rails/item';
import generateItemTitle from '~/utils/generateItemTitle';

export interface ListFlowState {
  id?: number;

  status: 'none' | 'initializing' | 'done' | 'publishing' | 'saving';
  info: ListFlowInfoState;
  photos: ListFlowPhotoState;
  pricing: ListFlowPricingState;
  shipping: ListFlowShippingState;

  errors: null | FieldError[];
  itemSource?: string | 'import';
}

export const listFlowInitialState: ListFlowState = {
  status: 'none',
  info: listFlowInfoInitialState,
  photos: { images: [] },
  pricing: listFlowPricingInitialState,
  shipping: listFlowShippingInitialState,
  errors: null,
};

const listFlowReducer = createReducer(listFlowInitialState, builder => {
  const re = /^listFlow/;
  builder
    .addCase(startInitializationPhase, (state, action) => ({
      ...listFlowInitialState,
      id: action.payload,
      status: 'initializing',
    }))
    .addCase(initializeFromItem, (state, action) => {
      state.status = 'initializing';
      state.id = action.payload.id;
      state.errors = null;
      state.itemSource = action.payload.source;
    })
    .addCase(finishInitializingItem, state => {
      state.status = 'done';
    })
    // Send all actions to their children reducers
    .addMatcher(
      action => re.test(action.type),
      (state, action) => {
        Object.assign(state, {
          info: listFlowInfoReducer(state.info, action),
          photos: listFlowPhotoReducer(state.photos, action),
          pricing: listFlowPricingReducer(state.pricing, action),
          shipping: listFlowShippingReducer(state.shipping, action),
        });
      },
    )
    // Must be run after all other info mutations take place
    .addMatcher(
      isAnyOf(
        initializeDetails,
        initializeCategory,
        changeDetail,
        changeCategory,
        changeModel,
      ),
      state => {
        state.info.generatedTitle = generateItemTitle(
          current(state.info.details),
          state.info.category ? current(state.info.category) : undefined,
          state.info.model ? current(state.info.model) : undefined,
        );

        state.info.usingGeneratedTitle =
          state.info.generatedTitle === state.info.title;

        // Swap to using generated title when existing title is empty
        if (!state.info.title) {
          state.info.usingGeneratedTitle = true;
        }
      },
    )
    .addMatcher(
      isRejected(listFlowSaveDraft, listFlowPublish, listFlowValidate),
      (state, action) => {
        state.status = 'done';
        if (Array.isArray(action.error)) {
          state.errors = action.error;
        } else {
          const errorMessage = getErrorMessage(action.error);
          if (errorMessage) {
            state.errors = [{ message: errorMessage }];
          }
        }
      },
    )
    .addMatcher(
      isPending(listFlowSaveDraft, listFlowPublish, listFlowValidate),
      state => {
        state.errors = null;
      },
    )
    .addMatcher(isPending(listFlowSaveDraft), state => {
      state.status = 'saving';
    })
    .addMatcher(isPending(listFlowPublish, listFlowValidate), state => {
      state.status = 'publishing';
    });
  // .addMatcher(
  //   isFulfilled(listFlowSaveDraft, listFlowPublish, listFlowValidate),
  //   state => {
  //     state.status = 'done';
  //   },
  // );
});

export default listFlowReducer;

export const getListFlowStatus = (state: RootState) => state.listFlow.status;

export const isListFlowInitialized = (state: RootState) =>
  state.listFlow.status === 'done';

export const getListFlowItemId = (state: RootState) => state.listFlow.id;

export const getListFlowErrors = (state: RootState) => state.listFlow.errors;
export const getListFlowItemSource = (state: RootState) =>
  state.listFlow.itemSource;

// Creates a hash map where the key is the field and value is the message
export const getListFlowFieldErrorMap = (state: RootState) => {
  // These are just hints, not anything definitive
  const map: { [P in keyof RailsItem]?: string } & Record<
    string,
    string | undefined
  > = {};

  if (!state.listFlow.errors) {
    return map;
  }

  for (let i = 0; i < state.listFlow.errors.length; i++) {
    const error = state.listFlow.errors[i];
    if (error.field && !map[error.field] && (error.detail || error.message)) {
      map[error.field] = error.detail || error.message;
    }
  }

  return map;
};

// Creates an array of all errors without field names
export const getListFlowFieldlessErrors = (state: RootState) =>
  state.listFlow.errors?.filter(e => !e.field);

export const getListFlowImageErrors = (
  state: RootState,
): string | undefined => {
  const re = /images?/i;
  return state.listFlow.errors?.find(e => (e.field ? re.test(e.field) : false))
    ?.message;
};

export const getListFlowPricingErrors = (
  state: RootState,
): string | undefined => {
  const re = /^(list|ask)?_?price/i;
  return state.listFlow.errors?.find(e => (e.field ? re.test(e.field) : false))
    ?.message;
};

export const getListFlowVariationError = (
  state: RootState,
): string | undefined => {
  const re = /variation/;
  return state.listFlow.errors?.find(e => (e.field ? re.test(e.field) : false))
    ?.message;
};

export const getListFlowShippingError = (
  state: RootState,
): string | undefined => {
  const re = /^(parcel|ship)/;
  return state.listFlow.errors?.find(e => (e.field ? re.test(e.field) : false))
    ?.message;
};

export const getListFlowTitleError = (state: RootState): string | undefined => {
  const re = /^(name|title)/;
  return state.listFlow.errors?.find(e => (e.field ? re.test(e.field) : false))
    ?.message;
};

export const getListFlowAutoPriceDropError = (
  state: RootState,
): string | undefined => {
  const re = /^auto_price/;
  return state.listFlow.errors?.find(e => (e.field ? re.test(e.field) : false))
    ?.message;
};

export const getListFlowMinimumOfferError = (
  state: RootState,
): string | undefined => {
  const re = /^minimum_offer/;
  return state.listFlow.errors?.find(e => (e.field ? re.test(e.field) : false))
    ?.message;
};

export const getListFlowSubmittableItem = (rootState: RootState) => {
  const state = rootState.listFlow;
  const item: Partial<PublishRailsItem> = {};

  if (state.id) {
    item.id = state.id;
  }

  if (state.info.category) {
    item.category_ids = [state.info.category.id];
  }

  if (state.info.details) {
    item.details = [];

    const values = Object.values(state.info.details);
    for (let i = 0; i < values.length; i++) {
      const detail = values[i];
      // Ensure it's selected with a valid slug
      if (detail.selected !== 'variations' && detail.selected?.slug) {
        item.details.push(detail.selected.slug);
      }
    }
  }

  item.model_id = state.info.model?.id ?? null;

  item.cost = state.info.privateDetails.myCost;
  item.inventory_id = state.info.privateDetails.inventoryId;
  item.mpn = state.info.privateDetails.mpn;
  item.gtin = state.info.privateDetails.gtin;

  item.list_price = state.pricing.listPrice;
  item.price_retail = state.pricing.retailPrice;

  if (state.pricing.isAuction) {
    item.accepts_offers = true;
    item.auction = state.pricing.isAuction;
    item.auction_started_at = state.pricing.auctionStartDate
      ? new Date(state.pricing.auctionStartDate).toJSON()
      : undefined;
  } else {
    item.auction = false;
    item.accepts_offers = state.pricing.acceptOffers;
  }

  if (state.info.variations && state.info.variations.length > 0) {
    item.variations = state.info.variations.map(variation => ({
      details: variation.details?.map(detail => detail.slug),
      id: variation.id,
      name: variation.name,
      quantity: variation.quantity,
    }));
  } else {
    item.quantity = state.info.quantity || 1;
  }

  item.shipping_insured = state.shipping.useShippingInsurance;

  if (state.shipping.useYourOwnLabel) {
    item.fixed_cost_shipping_price_ca = state.shipping.ownLabelPriceCA;
    item.fixed_cost_shipping_price_us = state.shipping.ownLabelPriceUS;
  } else {
    if (state.shipping.useDefaultParcel) {
      if (state.shipping.discount) {
        item.shipping_discount_amount = state.shipping.discount.amount;
      }
      if (state.info.category?.default_parcel?.id) {
        item.parcel_id = state.info.category.default_parcel.id;
      } else {
        item.parcel_id = state.shipping.customParcel?.id;
      }
    } else if (state.shipping.customParcel) {
      item.parcel_id = state.shipping.customParcel.id;
    }
  }

  if (state.info.usingGeneratedTitle) {
    item.name = state.info.generatedTitle;
  } else {
    item.name = state.info.title;
  }

  item.auto_price_drop = state.pricing.autoPriceDrop.enabled;
  item.auto_price_drop_min_price = state.pricing.autoPriceDrop.minimum ?? null;

  // Use "null" to check if you have Sideline Pro. If "default" is a number, you have it.
  if (
    state.pricing.minimumOffer.default != null ||
    state.pricing.minimumOffer.enabled
  ) {
    item.minimum_offer_threshold = state.pricing.minimumOffer.enabled
      ? state.pricing.minimumOffer.amount / 100
      : state.pricing.minimumOffer.default
        ? state.pricing.minimumOffer.default / 100
        : null;
  }

  item.description = state.info.description;

  item.image_ids = state.photos.images
    .filter(image => !!image.railsId)
    .map(image => image.railsId!);

  return item;
};
