import { Uuid } from 'common-types';
import { fetcher, makeFetcher } from 'fetcher-session';
import { ListFlowParcel } from '~/store/listFlow/slices/shipping';
import { DiscountedShippingResponse } from '~/typings/services/commerce/shipping';
import {
  PublishRailsItem,
  RailsCategory,
  RailsDetail,
  RailsImage,
  RailsItem,
  RailsItemModel,
  RailsParcel,
} from '~/typings/services/rails/item';

export interface ImageRecognitionResponse {
  details: {
    type: string; // detail slug
    id: number; // selected detail ID
    confidence: number; // confidence in match
  }[];
}

export const postCreateParcel = (
  parcel: RailsParcel | ListFlowParcel | undefined,
) =>
  fetcher<RailsParcel>('/v1/parcels', {
    method: 'post',
    body: {
      parcel: {
        height: 5,
        width: 5,
        length: 5,
        ...parcel,
      } as ListFlowParcel,
    },
  });

export const fetchListFlowCategories = () =>
  fetcher<RailsCategory[]>('/v1/categories', {
    params: {
      context: 'list-flow',
    },
  });

export const fetchListFlowCategory = (categoryId: number | string) =>
  fetcher<RailsCategory>(`/v1/categories/${categoryId}`, {
    params: {
      context: 'list-flow',
    },
  });

export const fetchListFlowDetails = (categoryId: number | string) =>
  fetcher<RailsDetail[]>('/v1/details', {
    params: {
      category_id: categoryId,
      scope: 'listable',
      context: 'list-flow',
    },
  });

export const fetchListFlowModels = (
  categoryId: number | string,
  brandId?: number | string,
) =>
  fetcher<RailsItemModel[]>('/v1/models', {
    params: {
      category_id: categoryId,
      brand_id: brandId,
      context: 'list-flow',
    },
  });

export const fetchModelsWithVersions = (
  categoryId: number | string,
  brandId?: number | string,
) =>
  fetcher<RailsItemModel[]>('/v1/models', {
    params: {
      category_id: categoryId,
      brand_id: brandId,
      has_versions: '1',
      context: 'list-flow',
    },
  });

export const fetchListFlowItem = makeFetcher<RailsItem>(
  (itemId: string | number) => [
    `/v1/items/${itemId}/edit`,
    {
      params: {
        track: false,
      },
    },
  ],
);

export const postDuplicateItemById = makeFetcher<RailsItem, [itemId: number]>(
  (itemId: string | number) => [
    `/v1/items/${itemId}/duplicate`,
    {
      method: 'post',
    },
  ],
);

interface CreateModelParams {
  brandId: number;
  categoryId: number;
  name: string;
}

export const postCreateModel = makeFetcher<
  RailsItemModel,
  [params: CreateModelParams]
>((params: CreateModelParams) => [
  '/v1/models',
  {
    body: {
      model: {
        name: params.name,
        category_id: params.categoryId,
        brand_id: params.brandId,
      },
    },
    method: 'post',
  },
]);

export const postUploadImage = (file: File | Blob) => {
  const data = new FormData();
  data.append('image', file);

  return fetcher<RailsImage>('/v1/images', {
    method: 'post',
    blob: true,
    body: data,
  });
};

export interface DiscountedShippingOptions {
  defaultParcel?: boolean;
  shipsFromCountry?: string;
  categoryUuid?: Uuid;
  parcelUuid?: Uuid;
  listPrice?: number;
}

export const fetchDiscountedShippingOptions = (
  options: DiscountedShippingOptions,
) =>
  fetcher<DiscountedShippingResponse>('/commerce/ship/v1/discounts/options', {
    params: {
      default_parcel: options?.defaultParcel,
      ship_from_country: options?.shipsFromCountry,
      primary_category: options?.categoryUuid,
      parcel_id: options?.parcelUuid,
      list_price: options?.listPrice,
    },
  });

export const createOrUpdateItem = async (item: Partial<PublishRailsItem>) => {
  const id = item.id;

  if (id) {
    return fetcher<RailsItem>(`/v1/items/${id}`, {
      method: 'put',
      body: { item },
    });
  } else {
    return fetcher<RailsItem>('/v1/items', {
      method: 'post',
      body: { item },
    });
  }
};

export const postImageRecognitionUri = makeFetcher<
  ImageRecognitionResponse,
  [uri: string]
>((uri: string) => [
  '/discovery/jobs/v1/detect',
  {
    method: 'post',
    body: {
      uri,
    },
  },
]);

export const postValidateItem = makeFetcher<RailsItem, [itemId: number]>(
  itemId => [
    `/v1/items/${itemId}/validate`,
    {
      method: 'post',
    },
  ],
);

export const putAddPhotosToItem = async (
  itemId: number,
  itemIds: PublishRailsItem['image_ids'],
) => {
  return fetcher<RailsItem>(`/v1/items/${itemId}`, {
    method: 'put',
    body: {
      item: {
        image_ids: itemIds,
      } as PublishRailsItem,
    },
  });
};

export function getItemDetailIds(item: RailsItem) {
  return item.details
    .flatMap(detailGroup => detailGroup.values?.map(detail => detail.id!) || [])
    .filter(Boolean);
}

export const fetchParcel = async (categoryId: number, detailIds: number[]) => {
  const parcels = await fetcher<RailsParcel[]>('/v1/parcels', {
    params: {
      category_id: categoryId,
      detail_ids: detailIds.join(','),
    },
  });

  return parcels[0];
};
